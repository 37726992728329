export const validateField = (field, value) => {
  switch (field) {
    case "graduationYear":
      return value ? "" : "Please select your graduation year";

    case "highSchoolName":
      return value.length >= 2 ? "" : "Please enter your high school name";

    case "instagramUsername":
      return value.length >= 2 ? "" : "Please enter your Instagram username";

    case "fullName":
      return value.length >= 2 ? "" : "Please enter your full name";

    case "email":
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value) ? "" : "Please enter a valid email address";

    case "phoneNumber":
      const phoneRegex = /^\d{10}$/;
      return phoneRegex.test(value.replace(/\D/g, ""))
        ? ""
        : "Please enter a valid 10-digit phone number";

    case "state":
      return value.length >= 2 ? "" : "Please enter your state";

    default:
      return "";
  }
};
