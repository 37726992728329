// src/App.js
import React, { useState, useEffect } from "react";
import IntroCard from "./components/IntroCard";
import QuestionCard1 from "./components/QuestionCard1";
import QuestionCard2 from "./components/QuestionCard2";
import ContactInfoCard from "./components/ContactInfoCard";
import PrivacyCard from "./components/PrivacyCard";
import AcceptanceCard from "./components/AcceptanceCard";
import SurveyFailedCard from "./components/SurveyFailedCard";
import useFormNavigation from "./hooks/useFormNavigation";
import {
  initFacebookPixel,
  trackFormStep,
  trackFormDisqualification,
  trackFormCompletion,
} from "./utils/FacebookPixel";

// Create a new component for the failed survey state
const SurveyFailedCardWithTracking = () => {
  useEffect(() => {
    trackFormDisqualification();
  }, []);

  return <SurveyFailedCard />;
};

function App() {
  const {
    currentStep,
    steps,
    formData,
    handleResponse,
    handleNextStep,
    handleSubmit,
  } = useFormNavigation();
  const [transitionStage, setTransitionStage] = useState("slide-in");

  useEffect(() => {
    initFacebookPixel();
  }, []);

  useEffect(() => {
    trackFormStep(steps[currentStep]);
  }, [currentStep, steps]);

  const handleTransition = (field, value) => {
    setTransitionStage("slide-out");
    setTimeout(() => {
      if (field && value) {
        handleNextStep(field, value);
      } else {
        handleNextStep();
      }
      setTransitionStage("slide-in");
    }, 300);
  };

  const handleFormSubmit = async () => {
    await handleSubmit();
    trackFormCompletion();
  };

  const renderStep = () => {
    switch (steps[currentStep]) {
      case "IntroCard":
        return <IntroCard onNext={() => handleTransition()} />;
      case "QuestionCard1":
        return (
          <QuestionCard1
            value={formData.graduationYear}
            onChange={(field, value) => handleResponse(field, value)}
            onNext={(field, value) => handleTransition(field, value)}
          />
        );
      case "QuestionCard2":
        return (
          <QuestionCard2
            formData={formData}
            onChange={(field, value) => handleResponse(field, value)}
            onNext={() => handleTransition()}
          />
        );
      case "ContactInfoCard":
        return (
          <ContactInfoCard
            formData={formData}
            onChange={(field, value) => handleResponse(field, value)}
            onNext={(field, value) => handleTransition(field, value)}
          />
        );
      case "PrivacyCard":
        return <PrivacyCard onSubmit={handleFormSubmit} />;
      case "AcceptanceCard":
        return <AcceptanceCard />;
      case "SurveyFailedCard":
        return <SurveyFailedCardWithTracking />;
      default:
        return <IntroCard onNext={() => handleTransition()} />;
    }
  };

  return (
    <div
      className={`min-h-screen flex flex-col items-center justify-center bg-primary ${transitionStage}`}
    >
      {renderStep()}
    </div>
  );
}

export default App;
