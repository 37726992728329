import React from "react";

function SurveyFailedCard() {
  return (
    <div className="flex flex-col items-center bg-white rounded-md shadow-md mx-auto card-large">
      <img
        src="/assets/logo.png"
        alt="Organization Logo"
        className="w-32 mb-6"
      />
      <h1 className="title-primary font-bold text-center mb-6">
        Thanks for your interest!
      </h1>
      <p className="text-center mb-6">
        Based on your answers, this might not be the best fit for you. We
        appreciate your interest in the role, check out this link to make an
        impact anyway.
      </p>
      <a
        href="http://bit.ly/turegs"
        target="_blank"
        rel="noopener noreferrer"
        className="button-large text-center bg-primary text-white font-bold rounded-full hover:bg-opacity-90 transition duration-300"
      >
        Learn More
      </a>
    </div>
  );
}

export default SurveyFailedCard;
