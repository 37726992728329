// src/components/AcceptanceCard.js
import React from "react";

function AcceptanceCard() {
  return (
    <div className="flex flex-col items-center bg-white rounded-md shadow-md mx-4 md:mx-auto card-large">
      <img
        src="/assets/logo.png"
        alt="Organization Logo"
        className="w-32 mb-6"
      />
      <h1 className="title-primary font-bold text-center mb-6">
        Thanks, you're all set!
      </h1>
      <p className="text-center mb-4">
        Your application for TurnUp's Voting Registration Ambassadorship has
        been received, and based on your replies, we are happy to extend you an
        offer!
      </p>
      <p className="text-center mb-6">
        Please check out the link below for the job description and
        requirements.
      </p>
      <a
        href="https://forms.gle/24LiFkvMiqoSuMnE8"
        target="_blank"
        rel="noopener noreferrer"
        className="button-large bg-primary text-white font-bold rounded-full hover:bg-opacity-90 transition duration-300"
      >
        View Job Requirements
      </a>
    </div>
  );
}

export default AcceptanceCard;
