// src/components/QuestionCard1.js
import React, { useState } from "react";
import { validateField } from "../utils/validation";

function QuestionCard1({ onNext, onChange, value }) {
  const [error, setError] = useState("");
  const years = Array.from({ length: 19 }, (_, i) => 2010 + i);

  const handleChange = (e) => {
    const newValue = e.target.value;
    onChange("graduationYear", newValue);
    if (newValue) {
      setError(validateField("graduationYear", newValue));
    } else {
      setError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newError = validateField("graduationYear", value);
    setError(newError);
    if (!newError) {
      onNext("graduationYear", value);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col items-center bg-white rounded-md shadow-md mx-auto card-large"
    >
      <img
        src="/assets/logo.png"
        alt="Organization Logo"
        className="w-32 mb-6"
      />
      <h2 className="title-primary font-bold text-center mb-6">
        High School Graduation Year
      </h2>
      <select
        value={value}
        onChange={handleChange}
        className={`form-input ${error ? "border-red-500" : ""}`}
      >
        <option value="">Select your graduation year</option>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      {error && <p className="text-red-500 text-sm mb-4 -mt-4">{error}</p>}
      <button
        type="submit"
        className="button-large bg-primary text-white font-bold rounded-full hover:bg-opacity-90 transition duration-300"
      >
        Next
      </button>
    </form>
  );
}

export default QuestionCard1;
