// src/hooks/useFormNavigation.js
import { useState } from "react";
const API_URL = "https://text.turnup.us:8001/api";
function useFormNavigation() {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    graduationYear: "",
    highSchoolName: "",
    instagramUsername: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    state: "",
  });

  const steps = [
    "IntroCard",
    "QuestionCard1",
    "QuestionCard2",
    "ContactInfoCard",
    "PrivacyCard",
    "AcceptanceCard",
    "SurveyFailedCard",
  ];

  // Move to the next step
  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  // Move to a specific step if needed
  const goToStep = (stepIndex) => {
    setCurrentStep(stepIndex);
  };

  // Handle user responses and conditional logic
  const handleResponse = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  // Handle navigation on button click
  const handleNextStep = (field, value) => {
    if (field === "graduationYear") {
      const graduationYear = parseInt(value, 10);
      if (graduationYear <= 2023) {
        goToStep(steps.indexOf("SurveyFailedCard"));
        return;
      }
    }
    nextStep();
  };

  // Submit handler for final form data submission
  const handleSubmit = async () => {
    try {
      const response = await fetch(`${API_URL}/submit-form`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (!data.success) {
        throw new Error(data.error);
      }

      console.log("Form submitted successfully");
      nextStep();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return {
    currentStep,
    steps,
    formData,
    handleResponse,
    handleNextStep,
    handleSubmit,
  };
}

export default useFormNavigation;
