// src/components/PrivacyCard.js
import React from "react";

function PrivacyCard({ onSubmit }) {
  return (
    <div className="flex flex-col items-center bg-white rounded-md shadow-md mx-auto card-large">
      <img
        src="/assets/logo.png"
        alt="Organization Logo"
        className="w-32 mb-6"
      />
      <h1 className="title-primary font-bold text-center mb-6">
        Privacy Policy
      </h1>
      <p className="text-center mb-6">
        By clicking Submit, you agree to send your info to TurnUp, who agrees to
        use it according to their{" "}
        <a
          href="https://www.turnup.us/privacy-policy-and-terms"
          className="text-primary hover:underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy policy
        </a>
        .
      </p>
      <button
        onClick={onSubmit}
        className="button-large bg-primary text-white font-bold rounded-full hover:bg-opacity-90 transition duration-300"
      >
        Submit
      </button>
    </div>
  );
}

export default PrivacyCard;
