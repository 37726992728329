// src/components/ContactInfoCard.js
import React, { useState } from "react";
import { validateField } from "../utils/validation";

function ContactInfoCard({ onNext, onChange, formData }) {
  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    state: "",
  });

  const handleChange = (field, value) => {
    onChange(field, value);
    setErrors((prev) => ({
      ...prev,
      [field]: validateField(field, value),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {
      fullName: validateField("fullName", formData.fullName),
      email: validateField("email", formData.email),
      phoneNumber: validateField("phoneNumber", formData.phoneNumber),
      state: validateField("state", formData.state),
    };

    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => !error)) {
      onNext("contactInfo", formData);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col items-center bg-white rounded-md shadow-md mx-auto card-large"
    >
      <img
        src="/assets/logo.png"
        alt="Organization Logo"
        className="w-32 mb-6"
      />
      <h2 className="title-primary font-bold text-center mb-6">Contact Info</h2>
      <div className="w-full">
        <input
          type="text"
          value={formData.fullName}
          onChange={(e) => handleChange("fullName", e.target.value)}
          className={`form-input ${errors.fullName ? "border-red-500" : ""}`}
          placeholder="Full Name"
        />
        {errors.fullName && (
          <p className="text-red-500 text-sm mb-4 -mt-4">{errors.fullName}</p>
        )}

        <input
          type="email"
          value={formData.email}
          onChange={(e) => handleChange("email", e.target.value)}
          className={`form-input ${errors.email ? "border-red-500" : ""}`}
          placeholder="Email"
        />
        {errors.email && (
          <p className="text-red-500 text-sm mb-4 -mt-4">{errors.email}</p>
        )}

        <input
          type="tel"
          value={formData.phoneNumber}
          onChange={(e) => handleChange("phoneNumber", e.target.value)}
          className={`form-input ${errors.phoneNumber ? "border-red-500" : ""}`}
          placeholder="Phone Number"
        />
        {errors.phoneNumber && (
          <p className="text-red-500 text-sm mb-4 -mt-4">
            {errors.phoneNumber}
          </p>
        )}

        <input
          type="text"
          value={formData.state}
          onChange={(e) => handleChange("state", e.target.value)}
          className={`form-input ${errors.state ? "border-red-500" : ""}`}
          placeholder="State"
        />
        {errors.state && (
          <p className="text-red-500 text-sm mb-4 -mt-4">{errors.state}</p>
        )}
      </div>
      <button
        type="submit"
        className="button-large bg-primary text-white font-bold rounded-full hover:bg-opacity-90 transition duration-300"
      >
        Next
      </button>
    </form>
  );
}

export default ContactInfoCard;
