// src/components/IntroCard.js
import React from "react";

function IntroCard({ onNext }) {
  console.log("IntroCard");
  return (
    <div className="flex flex-col items-center bg-white rounded-md shadow-md mx-auto card-large">
      <img
        src="/assets/logo.png"
        alt="Organization Logo"
        className="w-32 mb-6"
      />
      <h1 className="title-primary font-bold text-center mb-6">
        Apply to Get Paid $50 to Text Your Friends!
      </h1>
      <p className="text-center mb-6">
        We’re paying high school students $50 to text students in their high
        schools reminding them to register to vote. Apply here!
      </p>
      <button
        onClick={onNext}
        className="button-large bg-primary text-white font-bold rounded-full hover:bg-opacity-90 transition duration-300"
      >
        Start Application
      </button>
    </div>
  );
}

export default IntroCard;
