import ReactPixel from "react-facebook-pixel";

export const FB_PIXEL_ID = process.env.REACT_APP_FB_PIXEL_ID;

const options = {
  autoConfig: true,
  debug: false,
};

export const initFacebookPixel = () => {
  if (typeof window !== "undefined") {
    ReactPixel.init(FB_PIXEL_ID, undefined, options);
  }
};

// Custom events
export const trackFormStep = (step) => {
  ReactPixel.trackCustom("FormStep", { step });
};

export const trackFormDisqualification = () => {
  ReactPixel.trackCustom("FormDisqualified");
};

export const trackFormCompletion = () => {
  ReactPixel.trackCustom("FormCompleted");
};
