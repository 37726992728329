// src/components/QuestionCard2.js
import React, { useState } from "react";
import { validateField } from "../utils/validation";

function QuestionCard2({ onNext, onChange, formData }) {
  const [errors, setErrors] = useState({
    highSchoolName: "",
    instagramUsername: "",
  });

  const handleChange = (field) => (e) => {
    const newValue = e.target.value;
    onChange(field, newValue);
    setErrors((prev) => ({
      ...prev,
      [field]: validateField(field, newValue),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {
      highSchoolName: validateField("highSchoolName", formData.highSchoolName),
      instagramUsername: validateField(
        "instagramUsername",
        formData.instagramUsername
      ),
    };
    setErrors(newErrors);

    if (!newErrors.highSchoolName && !newErrors.instagramUsername) {
      onNext();
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col items-center bg-white rounded-md shadow-md mx-auto card-large"
    >
      <img
        src="/assets/logo.png"
        alt="Organization Logo"
        className="w-32 mb-6"
      />
      <div className="w-full space-y-6">
        <h2 className="title-primary font-bold text-center mb-6">
          School & Social Media
        </h2>
        <div>
          <input
            type="text"
            value={formData.highSchoolName}
            onChange={handleChange("highSchoolName")}
            className={`form-input w-full ${
              errors.highSchoolName ? "border-red-500" : ""
            }`}
            placeholder="Enter your high school name"
          />
          {errors.highSchoolName && (
            <p className="text-red-500 text-sm mb-4">{errors.highSchoolName}</p>
          )}
        </div>

        <div>
          <input
            type="text"
            value={formData.instagramUsername}
            onChange={handleChange("instagramUsername")}
            className={`form-input w-full ${
              errors.instagramUsername ? "border-red-500" : ""
            }`}
            placeholder="Enter your Instagram username"
          />
          {errors.instagramUsername && (
            <p className="text-red-500 text-sm mb-4">
              {errors.instagramUsername}
            </p>
          )}
        </div>
      </div>

      <button
        type="submit"
        className="button-large bg-primary text-white font-bold rounded-full hover:bg-opacity-90 transition duration-300 mt-6"
      >
        Next
      </button>
    </form>
  );
}

export default QuestionCard2;
